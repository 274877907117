@media print {
  body * {
    visibility: hidden;
  }

  #print-component1,
  #print-component1 * {
    visibility: visible;
  }

  #print-header1,
  #print-header1 * {
    visibility: visible;
  }

  @page {
    size: 'landscape'; /* Example size for thermal roll paper (width x height) */
    margin: 0; /* Remove any default margins to maximize use of paper */
    page-break-before: auto; /* Set page break behavior */
    page-break-after: auto;
    page-break-inside: avoid;
    /* media: print; */
  }
}

@media print {
  body {
    visibility: hidden;
    font-size: 12px;
  }

  #print-component,
  #print-component * {
    visibility: visible;
  }

  #print-component1,
  #print-component1 * {
    visibility: visible;
  }

  #print-header,
  #print-header * {
    visibility: visible;
    height: 20%;
    margin-top: 10px;
  }

  #print-header1,
  #print-header1 * {
    visibility: visible;
    height: 20%;
  }

  #print-component {
    position: absolute;
    left: 0;
    top: 0;
  }

  #print-component1 {
    position: absolute;
    left: 0;
    top: 20;
  }
}

@page {
  size: landscape; /* Adjust page size as needed */
  margin-top: 0.5cm; /* Set an equal margin on the top and bottom of each page */
  margin-bottom: 1cm; /* Adjust this value to set the desired bottom margin */
}
